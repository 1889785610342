import React from "react"

import { Link , graphql} from "gatsby"
import WaypointView from '../components/waypointview'
import Layout from "../components/layout"
//import Image from "../components/image"
import SEO from "../components/seo"
import CSlist from "../components/cslist"


// export default function Template({
//     data, // this prop will be injected by the GraphQL query below.
//   }) {
//     const { markdownRemark } = data // data.markdownRemark holds your post data
//     const { frontmatter, html } = markdownRemark
//     return (
//       <div className="blog-post-container">
//         <div className="blog-post">
//           <h1>{frontmatter.title}</h1>
//           <h2>{frontmatter.somevar}</h2>
//           <div
//             className="blog-post-content"
//             dangerouslySetInnerHTML={{ __html: html }}
//           />
//         </div>
//       </div>
//     )
//   }


export default function Template({data}){
    const { markdownRemark } = data // data.markdownRemark holds your post data
    const { frontmatter, html } = markdownRemark

    return (
  
        <Layout pageclass = 'white'>
        <SEO title="Case Studies" keywords={['webdesign', 'application', 'development']} />
        <WaypointView always="always"><span className='acc'>case </span><span>STUDIES</span></WaypointView>
  <h3 className='headlinedesc'>{frontmatter.title}<span class='number'>{frontmatter.number}</span></h3>
        
        
        <div className='services details textflow md' dangerouslySetInnerHTML={{ __html: html }}/>
    
    
        <div className='services textflow md'>-</div>

    


       

        
        <CSlist id='cslist' casetype={frontmatter.type} currentpath={frontmatter.path}/>


      </Layout>

    )

}



export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      id
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
        subtitle
        type
        number
      }
    }
  }
`